import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import { config } from '../../../public/config';
import 'react-modal-video/css/modal-video.min.css';
import 'animate.css/animate.min.css';
import { usePageData } from '../../data/usePageData';

const NavigationMenu = () => {
    const [menuToggle] = useState(false);
    const [mobileMenuToggle, setMobileMenuToggle] = useState(false);
    const { loading } = usePageData();

    return (
        <>
            {loading &&
                <div className="preloader">
                    <div className="main-circle">
                        <div className="green-circle">
                            <div className="brown-circle">

                            </div>
                        </div>
                    </div>
                </div>

            }
            {/* <!-- Start menubar area --> */}
            <section className={`menubar`}>
                <div className="container">
                    <div className="row">
                        
                        <div className="col-lg-12">
                            <nav className="navbar p-0 jus navbar-expand-xl">
                                <Link to="/" style={{ width: '200px', marginLeft: '20px' }}>
                                    <img src="/logo.png" alt="Logo" />
                                </Link>
                                <div className={`header-menu ml-auto position-static ${menuToggle === true ? 'menuToggle' : ''}`}>
                                    <div id="menu-button" onClick={() => { setMobileMenuToggle(!mobileMenuToggle) }}><i className="fas fa-bars"></i></div>
                                    <ul className={`menu ${mobileMenuToggle === true ? 'open' : ''}`}>
                                        <li><Link to="#" >About</Link>
                                            <ul>
                                                <li><Link to="/misvis">Mission & Vision</Link></li>
                                                <li><Link to="/governance">Governance</Link></li>
                                                <li><Link to="/documents">Documents </Link></li>
                                                <li><Link to="/policies">Policies </Link></li>
                                            </ul>
                                        </li>

                                        <li><Link to="#">Research & Innovation</Link>
                                            <ul>
                                                <li><Link to="/research-areas">Research Areas</Link></li>
                                                <li><Link to="/project">Projects</Link></li>
                                                <li><Link to="/services">Services</Link></li>
                                                <li><Link to="/facilities">Facilities</Link></li>
                                                <li><Link to="/networks">Networks</Link></li>
                                            </ul>
                                        </li>

                                        <li><Link to="/team">People</Link></li>

                                        <li><Link to="/publications">Publications</Link></li>
                                        
                                        <li><Link to="#">News & Events</Link>
                                            <ul style={{backgroundColor: 'white'}}>
                                                <li><Link to="/news">News</Link></li>
                                                <li><Link to="/events">Events</Link></li>
                                            </ul>
                                        </li>

                                        {/* <li><Link to="#">Indicators</Link>
                                            <ul style={{backgroundColor: 'white'}}>
                                                <li><Link to="/sdg">SDG</Link></li>
                                                <li><Link to="/indicators">General Indicators</Link></li>
                                            </ul>
                                        </li>   
                                        <li><Link to="/news">news</Link></li> */}
                                        
                                        <li><Link to="/contact">contacts</Link></li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default NavigationMenu;
