import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../data/PaginationPublication.js";
import Event from "../../data/Event.js";
import Button from 'react-bootstrap/Button'


const Events = () => {

    const [loading, setLoading] = useState(true);
    const [firstData, setFirstData] = useState([]);
    const [data, setData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [value, setValue] = React.useState([1992, (new Date().getFullYear())])
    const [isLast, setIstLast] = useState(false)
    const publicationPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);
    const [tipos, setTipos] = useState([]);
    const indexOfLastPublication = currentPage * publicationPerPage;
    const indexOfFirstPublication = indexOfLastPublication - publicationPerPage;
    const currentPublication = filteredData.slice(indexOfFirstPublication, indexOfLastPublication);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const mockTipos = ['Journal article', 'Conference abstract', 'Conference paper', 'Book', 'Book chapter'];
    const [others, setOthers] = useState([])

    useEffect(() => {
        try {
            axios.get(window.config.DEV_LINK + "/eventos/byUiId/" + window.config.id).then((res) => {
                setFirstData(res.data.payload)
                const parsedEvents = res.data.payload.map(item => ({
                    ...item,
                    DATA: new Date(item.data_inicio),
                }));

                console.log("parsed", parsedEvents)

                const sortedEvents = parsedEvents.sort((a, b) => b.DATA - a.DATA);
                const uniqueTypes = Array.from(new Set(sortedEvents.map(item => item.tipo)));
                setTipos(uniqueTypes);
                setSelectedFilters(uniqueTypes[0])
                const initialEvent = sortedEvents.filter((fPosts) => {
                    return fPosts.tipo === uniqueTypes[0];
                });

                setData(initialEvent)


            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {
            console.log(error)
        }
    }, []);


   
    function handlePosts(e) {
        let typeCargo = e.target.value;
        setSelectedFilters(typeCargo);

        let selectedEvents = firstData.filter((fPosts) => {
            return fPosts.tipo === typeCargo;
        });

        setData(selectedEvents);
    }


    const options = tipos.map(option => {
        const count = data.filter(publication => publication.tipo === option).length;

        return {
            label: `${option} (${count})`,
            value: option,
        };
    });

    console.log("data", data)

    return (
        <>
            <div>
                {options.length > 0 && (
                    <section className={`publications p-120 ${window.location.pathname === "/publications" ? "publications-page" : 3}`}>
                        {loading &&
                            <div className="preloader">
                                <div className="main-circle">
                                    <div className="green-circle">
                                        <div className="brown-circle">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        <div className="container">
                        <div className="row">
                        <div className="col col-lg-12 col-sm-12" style={{ textAlign: 'center' }}>
                            {tipos.map((cargo, index) => {
                                const isSelected = cargo === selectedFilters;
                                return (
                                    <Button
                                        className={`mybutton ${isSelected ? 'selected' : ''}`}
                                        size="lg"
                                        style={{ margin: '20px' }}
                                        key={index}
                                        value={cargo}
                                        onClick={handlePosts}
                                    >
                                        {cargo}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                            <div className="row">
                                {!isLast &&
                                    <div className="col-lg-12">
                                        <Event publication={data} loading={loading} />
                                    </div>
                                }
                                {isLast &&
                                    <div className="col-lg-12">
                                        <h5 style={{ textAlign: 'center', color: 'dimgray' }}>Please select at least one filter type</h5>
                                    </div>
                                }
                            </div>
                        </div>

                        {!isLast &&
                            <div className="row">
                                <div className="col-lg-12">
                                    <Pagination count={20} currentPage={currentPage} publicationPerPage={publicationPerPage} totalPublication={filteredData.length} paginate={paginate} />
                                </div>
                            </div>
                        }

                    </section>
                )}

            </div>

        </>

    );
}

export default Events;