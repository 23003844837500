import React from 'react';
import { Link } from 'react-router-dom';
import { sectionData } from './../../data/section.json';
import logoIPVC from '../../images/logo_ipvc.png';
import { usePageData } from '../../data/usePageData';
import { useConfigs } from '../../data/useConfigs';

function darkenColor(hexColor) {
    // Remove the '#' symbol if present
    hexColor = hexColor.replace('#', '');

    // Convert the hex color to RGB values
    var r = parseInt(hexColor.substr(0, 2), 16);
    var g = parseInt(hexColor.substr(2, 2), 16);
    var b = parseInt(hexColor.substr(4, 2), 16);

    // Apply the darkness factor to each RGB component
    r = Math.floor(r * 0.2);
    g = Math.floor(g * 0.2);
    b = Math.floor(b * 0.2);

    // Ensure the RGB values are within the valid range (0-255)
    r = Math.min(Math.max(r, 0), 255);
    g = Math.min(Math.max(g, 0), 255);
    b = Math.min(Math.max(b, 0), 255);

    // Convert the RGB values back to a hexadecimal color code
    var darkColor = "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);

    return darkColor;
}

// Helper function to convert a single RGB component to a two-digit hexadecimal value
function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}


const Footer = () => {

    const { pageData, loading } = usePageData();
    const configs = useConfigs();

    let data = sectionData.footer;
    let darkerHexa = pageData && darkenColor(pageData.hexa);
    return (
        <>
            <div style={{ minHeight: 'calc(25vh - 60px)', display: 'flex', flexDirection: 'column' }}>
                {/* <!-- start footer area --> */}
                {pageData &&
                    configs &&
                    <footer style={{ background: darkerHexa, backgroundSize: "cover" }}>
                        <div className="overlay">
                            {/* <!-- start footer-top area --> */}
                            <section className="footer-top">
                                <div className="container-fluid">
                                    <div className="row" style={{ textAlign: 'center', justifyContent: 'center' }}>
                                        {window.config.FCT === 1 &&
                                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 image-col">
                                                <img src={"https://adit.ipvc.pt/modulosipvc_producao/frontend/images/fct/fct__2_-removebg-preview.png"} alt="FCT" />
                                            </div>
                                        }
                                        {window.config.FCT === 1 &&
                                            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 image-col">
                                                <img src={"https://adit.ipvc.pt/modulosipvc_producao/frontend/images/fct/republica-removebg-preview.png"} alt="FCT" />
                                            </div>
                                        }
                                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 image-col">
                                            {pageData && <img alt="Logo CISAS" src={window.config.HOME_LINK + pageData.logotipo_branco_path} />}
                                        </div>
                                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 image-col">
                                            <img alt="Logo Ipvc" src={logoIPVC} />
                                        </div>
                                        <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 image-col">
                                            <div>
                                                <Link to="/contact">
                                                    <p style={{ fontSize: "15px" }}><b>{data.contactUs}</b></p>
                                                </Link>
                                                <p style={{ fontSize: "12px" }}>{data.titlePhone} {configs.find(item => item.id === "8")?.valor}</p>
                                                <p style={{ fontSize: "12px" }}>{data.titleEmail} {pageData.email_unidade}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>

                            {/* <!-- end footer-top area --> */}

                            {/* <!-- start footer-bottom area --> */}
                            <section className="footer-bottom">
                                <div className="container">
                                    <div className="footer-bottom-content">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12 col-xl-12 col-xxl-12">
                                                <div className="copyright">
                                                    <p>
                                                        {new Date().getFullYear()} <b>{window.config.NAME}</b>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            {/* <!-- end footer-bottom area --> */}
                        </div>
                    </footer>
                }
            </div>
            {/* <!-- end footer area --> */}
        </>
    )
}
export default Footer;