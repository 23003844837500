import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "../../data/PaginationFrame.js";
import Frame from "../../data/Frame.js";
import { Checkbox } from 'antd'
import { Slider } from '@mui/material';


const ProjectV2 = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [value, setValue] = React.useState([1992, (new Date().getFullYear())])
    const [isLast, setIstLast] = useState(false)
    const publicationPerPage = 20;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastPublication = currentPage * publicationPerPage;
    const indexOfFirstPublication = indexOfLastPublication - publicationPerPage;
    const currentPublication = filteredData.slice(indexOfFirstPublication, indexOfLastPublication);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const tipos = ['National', 'International']
    const [others, setOthers] = useState([])


    function decodeHtmlEntities(encodedText) {
        const entities = {
            "&lt;": "<",
            "&gt;": ">",
            "&#039;": "'",
        };
        return encodedText.replace(/(&lt;|&gt;|&#039;)/g, (match) => entities[match]);
    }

    useEffect(() => {
        try {
            axios.get(window.config.DEV_LINK + "/projetos/ui/" + window.config.id).then((res) => {
                for (let index = 0; index < res.data.payload.length; index++) {
                    const element = res.data.payload[index];
                    element.objetivos = decodeHtmlEntities(element.objetivos);
                    element.objetivos = element.objetivos.replace(/(<([^>]+)>)/ig, "")
                    element.objetivos = element.objetivos.replace(".", "")
                    element.atividades = decodeHtmlEntities(element.atividades);
                    element.atividades = element.atividades.replace(/(<([^>]+)>)/ig, "")
                    element.objetivo_principal = decodeHtmlEntities(element.objetivo_principal);
                    element.objetivo_principal = element.objetivo_principal.replace(/(<([^>]+)>)/ig, "")
                }
                for (let index = 0; index < res.data.payload.length; index++) {
                    const element = res.data.payload[index]
                    if (element.ds_tipo === 'Projetos -> Internacionais') {
                        others.push(element)
                    }
                }
                const semPrestacaoServico = res.data.payload.filter(projeto => projeto.ds_tipo !== "Prestações de Serviço -> Outros" && projeto.ds_tipo !== "Prestações de Serviço -> Serviços" && projeto.ds_tipo !== "Prestações de Serviço -> I&D" && projeto.ds_tipo !== "Prestações de Serviço -> Consultoria")
                
                semPrestacaoServico.sort((a, b) => {
                    return new Date(b.data_ini) - new Date(a.data_ini);
                });

                setData(semPrestacaoServico)
                setSelectedFilters(options)
            }).finally(() => {
                setLoading(false);
            });
        } catch (error) {
            console.log("ERROR", error);
        }
    }, []);



    const handleCheckboxChange = values => {
        setSelectedFilters(values);
        setCurrentPage(1)
    };

    useEffect(() => {
        if (selectedFilters.length === 0 && !loading) {
            setIstLast(true);
        } else {
            setIstLast(false);
        }
    }, [selectedFilters, loading]);;

    const filterData = () => {
        if (data) {
            let filteredData = [...data]; // Create a copy of the original data array

            if (selectedFilters.length === 1 && selectedFilters[0] === 'National') {
                filteredData = filteredData.filter(item => item.ds_regiao !== 'Europeu' && item.ds_regiao !== 'Ibero-Americano' && item.ds_regiao !== 'Transfronteiriço');
            }

            if (selectedFilters.length === 1 && selectedFilters[0] === 'International') {
                filteredData = filteredData.filter(item => {
                    if (item.ds_tipo) {
                        return item.ds_regiao === 'Europeu' || item.ds_regiao === 'Ibero-Americano' || item.ds_regiao === 'Transfronteiriço';
                    }
                    return false;
                });
            }

            if (value && value.length === 2) {
                filteredData = filteredData.filter(item => {
                    const itemYear = new Date(item.data_ini).getFullYear();
                    return itemYear >= value[0] && itemYear <= value[1];
                });
            }

            return filteredData;
        }
    };



    useEffect(() => {
        const result = filterData();
        setFilteredData(result);
    }, [selectedFilters, value]);


    const handleChange = (event, newValue) => {
        filterData()
        setValue(newValue);
        setCurrentPage(1)
        return false
    }

    const getCountByFilter = (data, filterOption) => {
        if (!data || !filterOption) {
          return 0;
        }
      
        let count = 0;
      
        if (filterOption === 'National') {
          count = data.filter(item => item.ds_regiao !== 'Europeu' && item.ds_regiao !== 'Ibero-Americano' && item.ds_regiao !== 'Transfronteiriço').length;
        } else if (filterOption === 'International') {
          count = data.filter(item => item.ds_regiao === 'Europeu' || item.ds_regiao === 'Ibero-Americano' || item.ds_regiao === 'Transfronteiriço').length;
        }
      
        // Add more conditions for other filters if needed
      
        return count;
      };
      
      const options = tipos.map(option => {
        const count = getCountByFilter(data, option);
      
        return {
          label: `${option} (${count})`,
          value: option,
        };
      });

    const marks = [
        {
            value: 1992,
            label: 1992,
            style: {
                color: "black",
            },
        },
        {
            value: new Date().getFullYear(),
            label: new Date().getFullYear(),
            style: {
                color: "black",
            },
        },
    ];

    return (
        <>

            <div>
                {options.length > 0 && (
                    <section className={`project p-120 ${window.location.pathname === "/project" ? "project-page" : 3}`}>
                        {loading &&
                            <div className="preloader">
                                <div className="main-circle">
                                    <div className="green-circle">
                                        <div className="brown-circle">

                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-2">
                                    <div className="card">
                                        <div className="card-header-mine">
                                            <h5 className="card-tittle-mine">Filters</h5>
                                        </div>
                                        <div className="filter-tittle card-subtitle mb-2 text-muted">Types</div>

                                        <div className="card-body-two">
                                            <Checkbox.Group options={options} defaultValue={options} onChange={handleCheckboxChange} style={{ display: "flex", flexDirection: "column", marginLeft: "8px" }}>
                                                {options.map((option, index) => (
                                                    <div className="form-check" key={index}>
                                                        <input className="form-check-input" type="checkbox" value={option.value} id={`checkbox-${index}`} />
                                                        <label className="form-check-label" htmlFor={`checkbox-${index}`}>{option.label}</label>
                                                    </div>
                                                ))}
                                            </Checkbox.Group>
                                        </div>
                                        <hr></hr>
                                        <div className="filter-tittle card-subtitle mb-2 text-muted">Year</div>

                                        <div className="card-body-two" style={{ textAlign: 'center' }}>
                                            <Slider
                                                getAriaLabel={() => "Year range"}
                                                value={value}
                                                onChange={handleChange}
                                                valueLabelDisplay="auto"
                                                min={1992}
                                                max={(new Date().getFullYear())}
                                                marks={marks}
                                            />

                                        </div>
                                    </div>
                                </div>

                                {!isLast &&
                                    <div className="col-lg-10">
                                        <Frame frames={currentPublication} loading={loading} />
                                    </div>
                                }
                                {isLast &&
                                    <div className="col-lg-10">
                                        <h5 style={{ textAlign: 'center', color: 'dimgray' }}>Please select at least one filter type</h5>
                                    </div>
                                }
                            </div>
                        </div>
                        {!isLast &&
                            <div className="row">
                                <div className="col-lg-12">
                                    <Pagination count={20} currentPage={currentPage} framesPerPage={publicationPerPage} totalFrames={filteredData.length} paginate={paginate} />
                                </div>
                            </div>
                        }

                    </section>
                )}
            </div>
        </>
    );
}


export default ProjectV2;
