import React from 'react';
import AppRoute from '../components/route-components/Approute';
import { usePageData } from '../data/usePageData';
import setBodyColor from '../data/setcolor';


function App() {

  const { pageData, loading } = usePageData();

  if (!pageData) return null;

  pageData && setBodyColor({color: pageData.hexa})

  return (
    <div className="App">
   <AppRoute />
   </div>
  );
}



export default App;
